<script>
import { Role } from "@/router/role";

export default {
  name: "nav",

  data() {
    return {
      allItems: [
        {
          _name: "CSidebarNavItem",
          name: "Dashboard",
          to: { name: "DeliveryServiceDashboard" },
          icon: "cil-speedometer",
        },

        // Delivery Service
        {
          _name: "CSidebarNavTitle",
          _children: ["Menute Delivery Service"],
          authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Hubs",
          to: { name: "Hubs" },
          icon: "cil-lan",
          authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Restaurants",
          to: { name: "DeliveryServiceRestaurants" },
          icon: "cil-truck",
          authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Couriers",
          to: { name: "Couriers" },
          icon: "cil-group",
          authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Invoices",
          to: { name: "CourierInvoices" },
          icon: "cil-spreadsheet",
          authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin, Role.InvoiceAdmin]
        },

        // Back to Menute
        {
          _name: "CSidebarNavItem",
          name: "Back to Menute",
          to: { name: "Dashboard" },
          icon: "cil-arrow-left",
          _class: "mt-auto font-weight-bold",
        },
      ],
    };
  },

  computed: {
    currentItems() {
      return this.allItems.filter((item) => {
        return (
          !item.authorize ||
          item.authorize.some((role) => this.authUserRoles.includes(role))
        );
      });
    },

    sidebarItems() {
      return [
        {
          _name: "CSidebarNav",
          _children: this.currentItems,
        },
      ];
    },
  },
};
</script>
