<template>
  <CSidebar
    color-scheme="light"
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"

  >
    <CSidebarBrand class="menute-brand" to="/">
      <img src="/img/menute.be/logo.png" class="c-sidebar-brand-full img-drop-shadow" />
      <img src="/img/menute.be/logo-icon.png" class="c-sidebar-brand-minimized img-drop-shadow" />
    </CSidebarBrand>

    <CRenderFunction flat :contentToRender="sidebarItems"/>

    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import SidebarItems from './SidebarItems'

export default {
  name: 'CourierSidebar',
  extends: SidebarItems,
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  }
}
</script>

<style scoped>
.menute-brand {
  background: #9b0a0a !important;
}
.menute-brand .img-drop-shadow {
  filter: drop-shadow(0 0 0.5rem #fff);
}
.menute-brand .c-sidebar-brand-full {
  width: 60%;
}
.menute-brand .c-sidebar-brand-minimized {
  width: 100%;
}

.c-sidebar.c-sidebar-light {
  background: #eaeaea !important;
}
</style>
